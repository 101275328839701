<template>
  <component
    :is="$sbLinkFix(blok.link) ? NuxtLink:'div'"
    v-editable="blok"
    :to="$sbLinkFix(blok.link)"
    :target="$sbLinkTarget(blok.link)"
    class="PromoBlock block w-full"
    :class="desktopWidth('full')"
  >
    <div
      :style="{backgroundColor : blok.backgroundColor.color}"
      class="relative"
      :class="[ratioClass, contentPadding]"
    >
      <div
        v-if="blok.asset && blok.asset.filename"
        class="w-full h-full absolute top-0 left-0"
      >
        <video
          v-if="checkIfVideoFile(blok.asset.filename)"
          width="w-full"
          height="h-full"
          class="w-full h-full object-cover"
          loop
          muted
          autoplay
          playsinline
        >
          <source :src="blok.asset.filename" type="video/mp4">
        </video>
        <nuxt-img
          v-else-if="blok.asset && blok.asset.filename"
          preset="standard"
          loading="lazy"
          :sizes="`sm:100vw desk:${imageSize}`"
          class="h-full w-full object-cover"
          :src="blok.asset.filename"
          :alt="blok.asset.alt"
        />
      </div>

      <div
        v-if="blok.desktopAsset && blok.desktopAsset.filename"
        class="hidden desk:block absolute w-full h-full top-0 left-0"
      >
        <video
          v-if="checkIfVideoFile(blok.desktopAsset.filename)"
          width="w-full"
          height="h-full"
          class="w-full h-full object-cover"
          loop
          muted
          autoplay
          playsinline
        >
          <source :src="blok.desktopAsset.filename" type="video/mp4">
        </video>
        <nuxt-img
          v-else-if="blok.desktopAsset && blok.desktopAsset.filename"
          preset="standard"
          loading="lazy"
          :sizes="`sm:100vw desk:${imageSize}`"
          class="h-full w-full object-cover"
          :src="blok.desktopAsset.filename"
          :alt="blok.desktopAsset.alt"
        />
      </div>

      <div class="relative h-full w-full">
        <div v-if="blok.highlightImage && blok.highlightImage.filename" class="absolute w-full h-full">
          <video
            v-if="checkIfVideoFile(blok.highlightImage.filename)"
            width="w-full"
            height="h-full"
            class="w-full h-full object-cover"
            loop
            muted
            autoplay
            playsinline
          >
            <source :src="blok.highlightImage.filename" type="video/mp4">
          </video>
          <nuxt-img
            v-else-if="blok.highlightImage && blok.highlightImage.filename"
            preset="standard"
            loading="lazy"
            :sizes="`sm:100vw desk:${imageSize}`"
            class="h-full w-full object-cover"
            :src="blok.highlightImage.filename"
            :alt="blok.highlightImage.alt"
          />
        </div>
        <div
          v-if="blok.desktopHighlightImage && blok.desktopHighlightImage.filename"
          class="hidden desk:block absolute w-full h-full"
        >
          <video
            v-if="checkIfVideoFile(blok.desktopHighlightImage.filename)"
            width="w-full"
            height="h-full"
            class="w-full h-full object-cover"
            loop
            muted
            autoplay
            playsinline
          >
            <source :src="blok.desktopHighlightImage.filename" type="video/mp4">
          </video>
          <nuxt-img
            v-else-if="blok.desktopHighlightImage && blok.desktopHighlightImage.filename"
            preset="standard"
            loading="lazy"
            :sizes="`sm:100vw desk:${imageSize}`"
            class="h-full w-full object-cover"
            :src="blok.desktopHighlightImage.filename"
            :alt="blok.desktopHighlightImage.alt"
          />
        </div>
        <div
          v-if="showTapes"
          class="TapeWrapper w-full h-full absolute type-headline-xxs desk:type-headline-xs"
          :class="{
            'text-darkest': isLight(blok.tapeBackgroundColor.color),
            'text-lightest': !isLight(blok.tapeBackgroundColor.color)
          }"
        >
          <div
            :style="{backgroundColor : blok.tapeBackgroundColor.color}"
            class="TapeLeft flex items-center justify-center pl-[3px] w-24 desk:w-32 h-full absolute top-0 bottom-0 left-0 overflow-hidden"
          >
            <div class="flex gap-8 desk:gap-12 whitespace-nowrap -rotate-90">
              <span
                v-for="n in 34"
                :key="n"
                class="odd:font-normal"
              >
                {{ blok.tapeText }}
              </span>
            </div>
          </div>
          <div
            :style="{backgroundColor : blok.tapeBackgroundColor.color}"
            class="TapeBottom flex items-center justify-center pb-[3px] w-full h-24 desk:h-32 absolute right-0 bottom-0 left-0 overflow-hidden"
          >
            <div class="flex gap-8 desk:gap-12 whitespace-nowrap rotate-180">
              <span
                v-for="n in 34"
                :key="n"
                class="odd:font-normal"
              >
                {{ blok.tapeText }}
              </span>
            </div>
          </div>
          <div
            :style="{backgroundColor : blok.tapeBackgroundColor.color}"
            class="TapeRight flex items-center justify-center pr-[3px] w-24 desk:w-32 h-full absolute top-0 right-0 bottom-0 overflow-hidden"
          >
            <div class="flex gap-8 desk:gap-12 whitespace-nowrap rotate-90">
              <span
                v-for="n in 34"
                :key="n"
                class="odd:font-normal"
              >
                {{ blok.tapeText }}
              </span>
            </div>
          </div>
          <div
            :style="{backgroundColor : blok.tapeBackgroundColor.color}"
            class="TapeTop flex items-center justify-center pt-[3px] w-full h-24 desk:h-32 absolute top-0 left-0 overflow-hidden"
          >
            <div class="flex gap-8 desk:gap-12 whitespace-nowrap">
              <span
                v-for="n in 34"
                :key="n"
                class="odd:font-normal"
              >
                {{ blok.tapeText }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <nuxt-img
        v-if="blok.splashImage && blok.splashImage.filename"
        preset="standard"
        loading="lazy"
        :sizes="`sm:100vw desk:${imageSize}`"
        class="spin absolute top-16 left-16 w-64 h-64"

        :class="[topSplashSize]"
        :src="blok.splashImage.filename"
        :alt="blok.splashImage.alt"
      />
      <div
        v-if="blok.splashDiscountText || blok.splashDiscountNumber"
        class="
          absolute bottom-16 right-16 w-64 h-64
          bg-darkest rounded-full text-lightest
          flex flex-col items-center justify-center"
        :class="[bottomSplashSize]"
      >
        <div
          v-if="blok.splashDiscountText"
          class="-mb-2 mt-2"
          :class="[discountTextSize]"
        >
          {{ blok.splashDiscountText }}
        </div>
        <div
          v-if="blok.splashDiscountNumber"
          :class="[discountNumberSize]"
        >
          {{ blok.splashDiscountNumber }}%
        </div>
      </div>
      <nuxt-img
        v-if="blok.splashDiscountImage && blok.splashDiscountImage.filename"
        preset="standard"
        loading="lazy"
        :sizes="`sm:100vw desk:${imageSize}`"
        class="absolute bottom-16 right-16 w-64 h-64"
        :class="[bottomSplashSize]"
        :src="blok.splashDiscountImage.filename"
        :alt="blok.splashDiscountImage.alt"
      />
    </div>
    <div
      v-if="blok.preamble || blok.title || blok.tagline || blok.buttonText"
      class="flex flex-col px-12 pt-16"
      :class="{
        'items-center text-center': !blok.textAlign || blok.textAlign === 'center',
        'items-start': blok.textAlign === 'left',
        'items-end': blok.textAlign === 'right',
      }"
    >
      <div
        v-if="blok.preamble"
        class="underline"
        :class="[preambleSize]"
      >
        {{ blok.preamble }}
      </div>
      <div
        v-if="blok.title"
        class="mt-8"
        :class="[titleSize]"
      >
        {{ blok.title }}
      </div>
      <div
        v-if="blok.tagline"
        class="mt-2"
        :class="[taglineSize]"
      >
        {{ blok.tagline }}
      </div>
      <div v-if="blok.buttonText" class="mt-12">
        <div class="btn">{{ blok.buttonText }}</div>
      </div>
    </div>
  </component>
</template>

<script setup lang="ts">
import { PromoBlock } from '~/constants/types/storyblok';
import useDesktopWidth from '~/composeables/useDesktopWidth';
import { checkIfVideoFile } from '~/util/imageUtil';
import { AspectRatioKeys } from '~/constants/aspectRatioKeys';
import { isLight } from '~/util/colorUtil';

const NuxtLink = resolveComponent('NuxtLink');

type Props = {
  blok: PromoBlock;
  size?: 'full' | 'half' | 'third' | 'fourth'; // size if in a promowrapper
  insideGrid?: boolean;
  placement: number;
  aspectRatioMobile?: AspectRatioKeys;
  aspectRatioDesktop?: AspectRatioKeys;
}

const props = withDefaults(defineProps<Props>(), {
  size: 'full',
  aspectRatioMobile: AspectRatioKeys.Square,
  aspectRatioDesktop: AspectRatioKeys.Landscape,
});
const { desktopWidth } = useDesktopWidth(props);

const preambleSize = computed(()=> {
  switch (props.size) {
    case 'full':
      return 'type-headline-sm underline decoration-[#C7C6C2]';
    case 'half':
      return 'type-headline-sm underline decoration-[#C7C6C2]';
    case 'third':
      return 'type-headline-xs underline decoration-[#C7C6C2]';
    case 'fourth':
      return 'type-headline-xs underline decoration-[#C7C6C2]';
  }
});
const titleSize = computed(()=> {
  switch (props.size) {
    case 'full':
      return 'type-headline-lg desk:type-headline-3xl';
    case 'half':
      return 'type-headline-lg desk:type-headline-2xl';
    case 'third':
      return 'type-headline-lg desk:type-headline-xl';
    case 'fourth':
      return 'type-headline-lg';
  }
});
const taglineSize = computed(()=> {
  switch (props.size) {
    case 'full':
      return 'type-sm desk:type-base';
    case 'half':
      return 'type-sm desk:type-base';
    case 'third':
      return 'type-sm desk:type-base';
    case 'fourth':
      return 'type-sm';
  }
});

const topSplashSize = computed(()=> {
  switch (props.size) {
    case 'full':
      return 'desk:top-56 desk:left-56 desk:w-112 desk:h-112';
    case 'half':
      return 'desk:top-32 desk:left-32 desk:w-96 desk:h-96';
    case 'third':
      return 'desk:top-20 desk:left-20 desk:w-80 desk:h-80';
  }});

const bottomSplashSize = computed(()=> {
  switch (props.size) {
    case 'full':
      return 'desk:bottom-56 desk:right-56 desk:w-112 desk:h-112';
    case 'half':
      return 'desk:bottom-32 desk:right-32 desk:w-96 desk:h-96';
    case 'third':
      return 'desk:bottom-20 desk:right-20 desk:w-80 desk:h-80';
  }});

const discountTextSize = computed(()=> {
  switch (props.size) {
    case 'full':
      return 'type-headline-xxs desk:type-headline-sm';
    case 'half':
      return 'type-headline-xxs desk:type-headline-xs';
    case 'third':
      return 'type-headline-xxs';
    case 'fourth':
      return 'type-headline-xxs';
  }
});

const discountNumberSize = computed(()=> {
  switch (props.size) {
    case 'full':
      return 'type-headline-lg desk:type-headline-2xl';
    case 'half':
      return 'type-headline-lg desk:type-headline-xl';
    case 'third':
      return 'type-headline-lg';
    case 'fourth':
      return 'type-headline-lg';
  }
});

const contentPadding = computed(()=> {
  switch (props.size) {
    case 'full':
      return 'p-32 desk:p-80';
    case 'half':
      return 'p-32 desk:p-56';
    case 'third':
      return 'p-32 desk:p-40';
    case 'fourth':
      return 'p-32';
  }
});

const imageSize = computed(()=> {
  switch (props.size) {
    case 'full':
      return '100vw';
    case 'half':
      return '50vw';
    case 'third':
      return '33vw';
    case 'fourth':
      return '25vw';
    default:
      return '1260px';
  }
});

const ratioClass = computed(()=> {
  let output = '';
  if (props.aspectRatioMobile !== '') {
    output += `mobOnly:${props.aspectRatioMobile} `;
  }
  if (props.aspectRatioDesktop !== '') {
    output += `desk:${props.aspectRatioDesktop}`;
  }
  return output;
});

const showTapes = computed(()=> {
  if (props.blok.splashImage &&  props.blok.splashImage.filename && props.blok.splashImage.filename !== '') {
    return false;
  }
  if (props.blok.splashDiscountImage &&  props.blok.splashDiscountImage.filename && props.blok.splashDiscountImage.filename !== '') {
    return false;
  }
  return props.blok.tapeText && props.blok.tapeBackgroundColor.color
      && props.blok.highlightImage && props.blok.highlightImage.filename
      && !props.blok.splashDiscountNumber && !props.blok.splashDiscountText;

});

</script>

<style scoped lang="postcss">
.spin {
  animation-name: spin;
  animation-duration: 20000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
</style>
